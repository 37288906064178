export const mainServiceBanners = [
  {
    title: 'Тормозная',
    subtitle: 'система',
    variant: 'large',
    bg: '1',
    img: '/tormoz.png',
    color: 'light',
    subcategory: 'Ходовая часть',
  },
  {
    title: `Жидкости`,
    subtitle: 'и фильтры',
    variant: 'small',
    bg: '2',
    img: '/oil.png',
    color: 'dark',
    subcategory: 'Жидкости и фильтры',
  },
  {
    title: 'Двигатель',
    variant: 'small',
    bg: '3',
    img: '/dvigatel.png',
    color: 'dark',
    subcategory: 'Двигатель',
  },
  {
    title: 'Рулевое',
    subtitle: 'управление',
    variant: 'large',
    bg: '4',
    img: '/wheel.png',
    color: 'dark',
    subcategory: 'Рулевое управление',
  },
];
